import React, { memo, useState, useCallback } from 'react';
import styled from 'styled-components';

// Config
import { collection } from '~/App/config/default-images';

// Shared components
import { InputWrapper } from '~/App/shared/components/Fields/InputWrapper';
import Text from '~/App/shared/components/Fields/Text';
import TextArea from '~/App/shared/components/Fields/TextArea';
import { ImageSelector } from '~/App/shared/components/Fields/ImageSelector';
import { FormSubHeading } from '~/App/shared/components/Donation/components/Forms/components/FormTypography';
import { StepWrapper } from '~/App/shared/components/Donation/components/Forms/components/FormLayout';
import * as MultiPartForm from '~/App/shared/components/MultiPartForm';
import { RadioButtonAccordion } from '~/App/shared/components/Elements/RadioButtonAccordion';

import { CollectionProgressPreview } from './CollectionProgressPreview';
import { HideCollectionProgressPreview } from './HideCollectionProgressPreview';
import { PreviewModal } from './PreviewModal';

const StyledInputWrapper = styled(InputWrapper)`
  max-width: 9.5rem;
`;

export default memo(function FormPartCollection({ values, validation, part }) {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handlePreviewClose = useCallback(() => setPreviewOpen(false), []);
  const handlePreviewOpen = useCallback(() => setPreviewOpen(true), []);

  return (
    <>
      <MultiPartForm.Section
        values={values}
        part={part}
        validation={validation}
        nextStepButton={{ text: 'Till kontaktuppgifter' }}
        title="Anpassa din insamling"
      >
        <StepWrapper>
          <FormSubHeading>Vad ska din insamling heta?</FormSubHeading>
          <InputWrapper>
            <Text
              name="name"
              value={values.collection.name}
              onChange={values.handlers.handleCollectionName}
              placeholder="Insamlingsnamn"
              autoComplete="off"
              onBlur={validation.showError.bind(undefined, 'collection.name')}
              errors={validation.errors['collection.name']}
              isValid={validation.isValid('collection.name')}
              isInvalid={validation.isInvalid('collection.name')}
              instructions="Namnet blir adressen till din insamling"
              required
            />
          </InputWrapper>
        </StepWrapper>
        <StepWrapper>
          <FormSubHeading>Välj en avsändare</FormSubHeading>
          <InputWrapper>
            <Text
              name="senderName"
              value={values.collection.senderName}
              onChange={values.handlers.handleSenderName}
              placeholder="Ditt namn, företaget, familjen..."
              autoComplete="off"
              onBlur={validation.handleBlur.bind(this, 'collection.senderName')}
              errors={validation.errors['collection.senderName']}
              isValid={validation.isValid('collection.senderName')}
              isInvalid={validation.isInvalid('collection.senderName')}
              instructions="Vem eller vilka står bakom insamlingen?"
            />
          </InputWrapper>
        </StepWrapper>
        <StepWrapper>
          <FormSubHeading>Välj en bild till din insamling</FormSubHeading>
          <ImageSelector
            defaultImages={collection}
            value={values.collection.image}
            onChange={values.handlers.handleCollectionImage}
            errors={validation.errors['collection.image']}
            isInvalid={validation.isInvalid('collection.image')}
          />
        </StepWrapper>
        <StepWrapper>
          <FormSubHeading>Hur mycket vill du samla in?</FormSubHeading>
          <StyledInputWrapper>
            <Text
              name="monetaryGoal"
              value={values.collection.monetaryGoal}
              onChange={values.handlers.handleCollectionMonetaryGoal}
              placeholder={
                values.collection.monetaryGoal ? 'Målsumma' : 't.ex. 3500'
              }
              autoComplete="off"
              suffix="kr"
              onBlur={validation.handleBlur.bind(
                this,
                'collection.monetaryGoal'
              )}
              errors={validation.errors['collection.monetaryGoal']}
              isValid={validation.isValid('collection.monetaryGoal')}
              isInvalid={validation.isInvalid('collection.monetaryGoal')}
            />
          </StyledInputWrapper>
        </StepWrapper>
        <StepWrapper>
          <FormSubHeading>Visa framsteg</FormSubHeading>
          <RadioButtonAccordion
            value={values.collection.showProgressBadge}
            onSelect={values.handlers.handleShowProgressBadge}
            items={[
              {
                id: true,
                name: 'Ja tack, visa andra hur min insamling går',
                children: (
                  <CollectionProgressPreview
                    handlePreviewOpen={handlePreviewOpen}
                  />
                )
              },
              {
                id: false,
                name: 'Nej tack, visa inte hur min insamling går',
                children: (
                  <HideCollectionProgressPreview
                    handlePreviewOpen={handlePreviewOpen}
                  />
                )
              }
            ]}
          />
        </StepWrapper>
        <StepWrapper>
          <FormSubHeading>Berätta varför du startar insamlingen</FormSubHeading>
          <InputWrapper>
            <TextArea
              name="description"
              value={values.collection.description}
              onChange={values.handlers.handleCollectionDescription}
              placeholder="Beskrivning"
              autoComplete="off"
              onBlur={validation.handleBlur.bind(
                this,
                'collection.description'
              )}
              errors={validation.errors['collection.description']}
              isValid={validation.isValid('collection.description')}
              isInvalid={validation.isInvalid('collection.description')}
            />
          </InputWrapper>
        </StepWrapper>
      </MultiPartForm.Section>
      <PreviewModal
        isOpen={previewOpen}
        onClose={handlePreviewClose}
        imageUrl={values.collection.image}
        collectionType={values.collection.collectionType}
        showProgressBadge={values.collection.showProgressBadge}
      />
    </>
  );
});
